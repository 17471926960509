var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('footer',{staticClass:"container"},[_c('div',{staticClass:"row row-cols-5 py-0 py-md-5 my-3 my-md-5"},[_c('div',{staticClass:"col-12 col-md-3"},[_c('div',{staticClass:"text-title"},[_vm._v(_vm._s(_vm.$t('footer.heading_menu')))]),_vm._v(" "),_c('ul',{staticClass:"nav flex-column"},[_c('li',{staticClass:"nav-item mb-2"},[_c('nuxt-link',{staticClass:"nav-link p-0 text-muted",attrs:{"custom":"","active-class":'active',"exact":"","lang":(_vm.$i18n.locale ==='ru')? 'ru':'en-US',"to":_vm.localePath('/')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var navigate = ref.navigate;
return [(!isActive)?_c('a',{staticClass:"nav-link p-0 text-muted",attrs:{"href":_vm.localePath('/')},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-home me-2"}),_vm._v(_vm._s(_vm.$t('footer.main'))+"\n            ")]):_c('span',{staticClass:"nav-link p-0 text-muted"},[_c('i',{staticClass:"fas fa-home me-2"}),_vm._v(_vm._s(_vm.$t('footer.main'))+"\n    ")])]}}])})],1),_vm._v(" "),_c('li',{staticClass:"nav-item mb-2"},[_c('nuxt-link',{staticClass:"nav-link p-0 text-muted",attrs:{"custom":"","active-class":'active',"lang":(_vm.$i18n.locale ==='ru')? 'ru':'en-US',"to":_vm.localePath('/blog')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var navigate = ref.navigate;
return [(!isActive)?_c('a',{staticClass:"nav-link p-0 text-muted",attrs:{"href":_vm.localePath('/blog')},on:{"click":navigate}},[_c('i',{staticClass:"far fa-newspaper me-2"}),_vm._v(_vm._s(_vm.$t('footer.blog'))+"\n            ")]):_c('span',{staticClass:"nav-link p-0 text-muted"},[_c('i',{staticClass:"far fa-newspaper me-2"}),_vm._v(_vm._s(_vm.$t('footer.blog'))+"\n    ")])]}}])})],1),_vm._v(" "),_c('li',{staticClass:"nav-item mb-2"},[_c('nuxt-link',{staticClass:"nav-link p-0 text-muted",attrs:{"custom":"","active-class":'active',"lang":(_vm.$i18n.locale ==='ru')? 'ru':'en-US',"to":_vm.localePath('/about')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var navigate = ref.navigate;
return [(!isActive)?_c('a',{staticClass:"nav-link p-0 text-muted",attrs:{"href":_vm.localePath('/about')},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-info-circle me-2"}),_vm._v(_vm._s(_vm.$t('footer.about'))+"\n            ")]):_c('span',{staticClass:"nav-link p-0 text-muted"},[_c('i',{staticClass:"fas fa-info-circle me-2"}),_vm._v(_vm._s(_vm.$t('footer.about'))+"\n    ")])]}}])})],1),_vm._v(" "),_c('li',{staticClass:"nav-item mb-2"},[_c('nuxt-link',{staticClass:"nav-link p-0 text-muted",attrs:{"custom":"","active-class":'active',"lang":(_vm.$i18n.locale ==='ru')? 'ru':'en-US',"to":_vm.localePath('/sitemap')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var navigate = ref.navigate;
return [(!isActive)?_c('a',{staticClass:"nav-link p-0 text-muted",attrs:{"href":_vm.localePath('/sitemap')},on:{"click":navigate}},[_c('i',{staticClass:"fas fa-sitemap me-2"}),_vm._v(_vm._s(_vm.$t('sitemap.title'))+"\n            ")]):_c('span',{staticClass:"nav-link p-0 text-muted"},[_c('i',{staticClass:"fas fa-sitemap me-2"}),_vm._v(_vm._s(_vm.$t('sitemap.title'))+"\n    ")])]}}])})],1)])]),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-3"},[_c('div',{staticClass:"text-title"},[_vm._v(_vm._s(_vm.$t('footer.heading_socials')))]),_vm._v(" "),_c('div',{domProps:{"innerHTML":_vm._s(_vm.settings['socials'])}})]),_vm._v(" "),_c('div',{staticClass:"col-12 col-md-3"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.settings['footer'])}}),_vm._v(" "),_c('p',{staticClass:"text-muted"},[_vm._v("2015 - "+_vm._s(_vm.getCurrentYear())+" "+_vm._s(_vm.$t('footer.copyright')))]),_vm._v(" "),_vm._m(0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',{staticClass:"text-muted"},[_c('i',{staticClass:"fas fa-star me-2"}),_c('a',{attrs:{"href":"https://www.elitepvpers.com/","target":"_blank"}},[_vm._v("Elitepvpers")])])}]

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import B2PayLogo from "./ui/B2PayLogo.vue";
import {mapGetters} from "vuex";

export default {
  name: 'TheFooter',
  components: {B2PayLogo},
  computed: {
    ...mapGetters({settings: 'getSettings'}),
  },
  methods: {
    getCurrentYear() {
      return new Date().getFullYear().toString();
    }
  }
}

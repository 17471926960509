//
//
//
//
//


export default {
  name: "VkMessages",
  data() {
    return {
      groupId: 103745867,
      elementId: 'vk_community_messages',
      widgetOptions: {
        disableButtonTooltip: 1,
        disableExpandChatSound: 1,
        expanded: 0,
      }
    }
  },
  head() {
    return {
      script: [
        {
          src: 'https://vk.com/js/api/openapi.js?169',
          async: true
        }
      ],
    }
  },
  mounted() {
    window.addEventListener('load', () => {
      console.log('loaded')
      if (this.$i18n.locale === 'ru') {
        setTimeout(() => {
          VK.Widgets.CommunityMessages(this.elementId, this.groupId, this.widgetOptions);
        }, 1000)
      }
    })
  }
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Breadcrumbs from '@/components/ui/Breadcrumbs'
import CheatScreenshots from '@/components/cheats/CheatScreenshots'
import CheatInfo from '@/components/cheats/CheatInfo'
import CheatActions from '@/components/cheats/CheatActions'
import CheatFeatures from '@/components/cheats/CheatFeatures'
import CheatReview from '@/components/cheats/CheatReview'
import CheatSystemRequirements from '@/components/cheats/CheatSystemRequirements'
import CheatAdditionalInfo from '@/components/cheats/CheatAdditionalInfo'

export default {
  name: 'TheCheat',
  props: {
    additional_info: String,
    additional_path: String,
    screenshots: Array,
    cheat: Object,
    game: Object,
  },
  components: {
    CheatReview,
    CheatFeatures,
    CheatActions,
    CheatInfo,
    Breadcrumbs,
    CheatScreenshots,
    CheatSystemRequirements,
    CheatAdditionalInfo
  },
  methods: {
    getTranslate() {
      const currentLangId = (this.$i18n.locale === 'ru') ? 0 : 1

      return this.cheat.translates[currentLangId]
    },
    getJSONLD() {
      const translate = this.getTranslate()
      let code = {
        '@context': 'https://schema.org',
        '@type': 'Product',
        'description': translate.meta_description,
        'name': translate.meta_title,
        'priceValidUntil': '2027-01-01',
        'offers': []
      }
      try {
        if (Array.isArray(this.cheat.price_plans)) {
          this.cheat.price_plans.forEach((pricePlan) => {
            const plan = Object.assign([], pricePlan)
            let currency, lang_id
            if (this.$i18n.locale === 'ru') {
              currency = 'RUB'
              lang_id = 1
            } else {
              currency = 'USD'
              lang_id = 2
            }
            const currentPlan = plan.translates.find((translate) => translate.language_id === lang_id)
            code['offers'].push({
              '@type': 'Offer',
              'availability': 'https://schema.org/InStock',
              'price': currentPlan.cost,
              'priceCurrency': currency
            })

          })
        }
      } catch (e) {
        console.warn(e)
      }

      try {
        code['image'] = process.env['APP_URL'] + '/storage/' + this.cheat.screenshots[0].path
      } catch (e) {

      }

      return code
    }
  },
  data() {
    const currentLangId = (this.$i18n.locale === 'ru') ? 0 : 1
    return {
      breadcrumbs: [
        {url: `/cheats/${this.game.url_path}`, text_translate: this.game.translates[currentLangId].heading},
        {
          url: `/cheats/${this.game.url_path}/${this.cheat.url_path}`,
          text_translate: this.cheat.name
        }
      ],
      system_requirements: {
        os: this.cheat.os,
        cpu: this.cheat.cpu,
        gpu: this.cheat.gpu,
        client: this.cheat.client,
      },
      review_link: this.cheat.review_link,
      iframe_link: this.cheat.iframe_link,
      local_link: this.cheat.local_link,
      price_plans: this.cheat.price_plans,
      instruction_link: this.cheat.instruction_link,
    }
  },
  head() {
    const currentLangId = (this.$i18n.locale === 'ru') ? 0 : 1
    let scripts = [{
      type: 'application/ld+json',
      json: this.getJSONLD()
    }]
    if (currentLangId === 1) {
      scripts.push({
        src: "https://lcab.talk-me.ru/support/support.js?h=3e62f7753836e2eee0a367a3ee21c5dd"
      })
      scripts.push({
        src: "https://static.site-chat.me/support/support.int.js?h=3e62f7753836e2eee0a367a3ee21c5dd"
      })
    }
    return {
      title: this.cheat.translates[currentLangId].meta_title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.cheat.translates[currentLangId].meta_description
        },
        {
          hid: 'viewport',
          name: 'viewport',
          content: 'width=device-width, initial-scale=1',
        }
      ],
      script: scripts
    }
  }
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('nav',{staticClass:"navbar navbar-expand-lg"},[_c('div',{staticClass:"container"},[_c('nuxt-link',{staticClass:"navbar-brand",attrs:{"custom":"","active-class":'active',"aria-current":"page","exact":"","lang":(_vm.$i18n.locale ==='ru')? 'ru':'en-US',"to":_vm.localePath('index')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var navigate = ref.navigate;
return [(!isActive)?_c('a',{staticClass:"nav-link nuxt-link-exact-active",attrs:{"href":_vm.localePath('index'),"aria-current":"page"},on:{"click":navigate}},[_vm._v("WH-SATANO")]):_c('span',{staticClass:"nav-link nuxt-link-exact-active active"},[_vm._v("WH-SATANO")])]}}])}),_vm._v(" "),_vm._m(0),_vm._v(" "),_c('div',{staticClass:"collapse navbar-collapse",attrs:{"id":"navbarSupportedContent"}},[_c('ul',{staticClass:"navbar-nav mx-auto mb-2 mb-lg-0"},[_c('li',{staticClass:"nav-item"},[_c('nuxt-link',{staticClass:"nav-link",attrs:{"custom":"","active-class":'active',"aria-current":"page","exact":"","lang":(_vm.$i18n.locale ==='ru')? 'ru':'en-US',"to":_vm.localePath('index')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var navigate = ref.navigate;
return [(!isActive)?_c('a',{staticClass:"nav-link nuxt-link-exact-active",attrs:{"href":_vm.localePath('index'),"aria-current":"page"},on:{"click":navigate}},[_vm._v(_vm._s(_vm.$t('home_page_link_header')))]):_c('span',{staticClass:"nav-link nuxt-link-exact-active active"},[_vm._v(_vm._s(_vm.$t('home_page_link_header')))])]}}])})],1),_vm._v(" "),_c('li',{staticClass:"nav-item dropdown"},[_c('a',{staticClass:"nav-link dropdown-toggle",attrs:{"href":"#","lang":(_vm.$i18n.locale ==='ru')? 'ru':'en-US',"id":"navbarDropdown","role":"button","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_vm._v("\n                        "+_vm._s(_vm.$t('private_hacks'))+"\n                    ")]),_vm._v(" "),_c('div',{staticClass:"dropdown-menu shadow games-list",attrs:{"aria-labelledby":"navbarDropdown"}},[_c('ul',{staticClass:"list-group list-group list-group-horizontal-md list-unstyled"},_vm._l((_vm.getChunkedGames()),function(gamesChunk){return _c('li',[_c('ul',{staticClass:"list-unstyled"},_vm._l((gamesChunk),function(game){return _c('li',[_c('nuxt-link',{staticClass:"dropdown-item",attrs:{"custom":"","lang":(_vm.$i18n.locale ==='ru')? 'ru':'en-US',"to":_vm.localePath({path:'/cheats/' + game.url_path})},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var navigate = ref.navigate;
return [(!isActive)?_c('a',{staticClass:"nav-link nuxt-link-exact-active",attrs:{"href":_vm.localePath({path:'/cheats/' + game.url_path}),"aria-current":"page"},on:{"click":navigate}},[_vm._v(_vm._s(game.short_name))]):_c('span',{staticClass:"nav-link nuxt-link-exact-active active"},[_vm._v(_vm._s(game.short_name))])]}}],null,true)})],1)}),0)])}),0)])]),_vm._v(" "),_c('li',{staticClass:"nav-item"},[_c('nuxt-link',{staticClass:"nav-link",attrs:{"to":_vm.localePath('blog'),"custom":"","active-class":'active',"exact":"","lang":(_vm.$i18n.locale ==='ru')? 'ru':'en-US'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var navigate = ref.navigate;
return [(!isActive)?_c('a',{staticClass:"nav-link nuxt-link-exact-active",attrs:{"href":_vm.localePath('blog'),"aria-current":"page"},on:{"click":navigate}},[_vm._v(_vm._s(_vm.$t('blog_page_link')))]):_c('span',{staticClass:"nav-link nuxt-link-exact-active active"},[_vm._v(_vm._s(_vm.$t('blog_page_link')))])]}}])})],1),_vm._v(" "),_c('li',{staticClass:"nav-item"},[_c('nuxt-link',{staticClass:"nav-link",attrs:{"to":_vm.localePath('about'),"active-class":'active',"exact":"","custom":"","lang":(_vm.$i18n.locale ==='ru')? 'ru':'en-US'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var isActive = ref.isActive;
var navigate = ref.navigate;
return [(!isActive)?_c('a',{staticClass:"nav-link nuxt-link-exact-active",attrs:{"href":_vm.localePath('about'),"aria-current":"page"},on:{"click":navigate}},[_vm._v(_vm._s(_vm.$t('about_page_link')))]):_c('span',{staticClass:"nav-link nuxt-link-exact-active active"},[_vm._v(_vm._s(_vm.$t('about_page_link')))])]}}])})],1)]),_vm._v(" "),_c('div',{staticClass:"d-flex additional-zone"},[_vm._m(1),_vm._v(" "),_c('div',{staticClass:"languages dropdown"},[_c('a',{staticClass:"nav-link dropdown-toggle",attrs:{"href":"#","id":"languagesDropdown","role":"button","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_c('img',{staticClass:"current-lang-icon",attrs:{"src":_vm.getLangIcon(),"alt":_vm.current_locale + 'flag',"width":"26px","height":"18px"}}),_vm._v("\n                        "+_vm._s(_vm.$i18n.localeProperties.native_name)+"\n                    ")]),_vm._v(" "),_c('ul',{staticClass:"dropdown-menu shadow-sm",attrs:{"aria-labelledby":"languagesDropdown"}},_vm._l((_vm.$i18n.locales),function(locale){return _c('li',{key:locale.code},[_c('a',{staticClass:"dropdown-item",attrs:{"lang":(locale.code ==='ru')? 'ru':'en-US',"href":_vm.switchLocalePath(locale.code)}},[_c('img',{attrs:{"src":locale.icon_path,"width":"26px","height":"18px","alt":locale.code + 'flag'}}),_c('span',[_vm._v(_vm._s(locale.native_name))])])])}),0)])])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"navbar-toggler",attrs:{"type":"button","data-bs-toggle":"collapse","data-bs-target":"#navbarSupportedContent","aria-controls":"navbarSupportedContent","aria-expanded":"false","aria-label":"Toggle navigation"}},[_c('i',{staticClass:"fas fa-bars"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"socials"},[_c('a',{staticClass:"nav-link p-0 text-muted",attrs:{"href":"https://discord.gg/Rf9CMHqFGH","aria-label":"Discord","target":"_blank"}},[_c('i',{staticClass:"fab fa-discord me-2"})]),_vm._v(" "),_c('a',{staticClass:"nav-link p-0 text-muted",attrs:{"href":"https://t.me/phoenix_satano","aria-label":"Telegram","target":"_blank"}},[_c('i',{staticClass:"fab fa-telegram-plane me-2"})])])}]

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {mapGetters} from "vuex";

export default {
    name: 'TheHeader',
    computed: {
        ...mapGetters({games: 'getGames'}),
        current_locale() {
            return this.$i18n.locale
        }
    },
    methods: {
        getLangIcon() {
            let locales = this.$i18n.locales
            let result = ''
            locales.forEach((locale) => {
                if (locale.code === this.current_locale) result = locale.icon_path
            })
            return result
        },
        getChunkedGames() {
            return this.chunk(this.games, 11)
        },
        chunk(arr, chunkSize) {
            if (chunkSize <= 0) throw "Invalid chunk size";
            let R = [];
            for (let i = 0, len = arr.length; i < len; i += chunkSize)
                R.push(arr.slice(i, i + chunkSize));
            return R;
        },
        getUrlToCategory(url_path) {
            let locale = this.$i18n.locale

            return this.$config.APP_URL + '/' + ((locale === 'en') ? 'en/' + `cheats/${url_path}` : `cheats/${url_path}`)
        }
    }
}

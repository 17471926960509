//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Breadcrumbs from "@/components/ui/Breadcrumbs";

export default {
    name: 'TheGame',
    props: {
        game: Object
    },
    data() {
        const currentLangId = (this.$i18n.locale === 'ru') ? 0 : 1

        return {
            breadcrumbs: [{url: `/cheats/csgo`, text_translate: this.game.translates[currentLangId].heading}]
        }
    },
    components: {Breadcrumbs},
    methods: {
        getTranslate() {
            const currentLangId = (this.$i18n.locale === 'ru') ? 0 : 1

            return this.game.translates[currentLangId]
        },
        getCheatAdvantageTranslate(cheat, advantageIndex) {
            const currentLangId = (this.$i18n.locale === 'ru') ? 0 : 1
            let result = ''
            cheat.advantages.forEach((advantage, index) => {
                if (index === advantageIndex) {
                    result = '<i class="fas fa-bolt me-2"></i>' + advantage.translates[currentLangId].name
                }
            })

            return result
        },
        getMinPrice(cheat) {
            const currentLangId = (this.$i18n.locale === 'ru') ? 0 : 1
            let minPrice = 0;

            cheat.price_plans.forEach((price) => {
                const cost = price.translates[currentLangId]?.cost ?? undefined;
                if (cost && minPrice === 0) {
                    minPrice = price.translates[currentLangId].cost;
                }

                if (cost && price.translates[currentLangId].cost < minPrice) {
                    minPrice = price.translates[currentLangId].cost;
                }
            })

            if (minPrice === 0) {
                return '<div class="price-mock">0</div>';
            }

            if (currentLangId === 0) {
                return `от ${minPrice} <span>₽</span>`
            }

            return `from <span>$</span>${minPrice}`
        }
    },
    created() {
        this.game.cheats.sort((a, b) => parseInt(a.position) - parseInt(b.position))
        this.game.cheats = this.game.cheats.filter((cheat) => cheat.is_active)
    },
    head() {
        const currentLangId = (this.$i18n.locale === 'ru') ? 0 : 1

        return {
            title: this.game.translates[currentLangId].meta_title,
            meta: [
                {
                    hid: 'description',
                    name: 'description',
                    content: this.game.translates[currentLangId].meta_description
                },
                {charset: 'utf-8'},
                {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                {hid: 'description', name: 'description', content: ''},
                {name: 'format-detection', content: 'telephone=no'},
                {name: 'yandex-verification', content: '5f68c461d73d1f52'}
            ],
        }
    },
}
